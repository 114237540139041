import { IPaginated } from './Paginated';
import { IClientAidExtendedData } from './ClientDraft';

export enum ClientKind {
  PERSONAL = 'personal',
  BUSINESS = 'entity',
}

export enum ClientState {
  APPROVED = 'approved',
  IN_REVIEW = 'in_review',
  CREATED = 'created',
  PRE_ACTIVATED = 'pre_activated',
  ACTIVE = 'active',
  REJECTED = 'rejected',
  ON_HOLD = 'on_hold',
  BLOCKED = 'blocked',
  INACTIVE = 'inactive',
  CLOSED = 'closed',
}

// client from api
export interface IClient {
  id: string;
  bankEntityId: string;
  clientId: string;
  companyRegistrationNumber: string;
  countryId: string;
  country: {
    displayName: string;
    id: string;
  };
  createdAt: string;
  displayName: string;
  dob: string;
  entityName: string;
  entityType: string;
  firstName: string;
  kind: string;
  lastName: string;
  primaryCurrencyId: string;
  specialActivities: string;
  state: ClientState;
  tariffId: string;
  tariff: {
    displayName: string;
    id: string;
  };
  updatedAt: string;
  aidCase?: {
    score: number;
  };
}

export interface IClientFeature {
  id: string;
  value: string;
  displayName: string;
  isDeleted: boolean;
}

// client from backoffice
export interface IClientShort {
  id: string;
  aidUrl: string;
  address: string | null;
  bcaState: ClientState;
  clientDraft: {
    id: string;
    aidCaseUrl: string;
    bcaState: ClientState;
    complianceTags: Array<{
      displayName: string;
      id: string;
    }>;
    displayName: string;
    entityName: string;
    firstName: string | null;
    kind: string;
    lastName: string | null;
    state: string;
    user: {
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      phone: string;
    };
    submittedAt: number | null;
    approvedAt: number | null;
  };
  complianceTags: { displayName: string; id: string }[];
  country: {
    id: string;
    createdAt: number;
    displayName: string;
    isDeleted: boolean;
    kind: string;
    symbol: string;
    updatedAt: number;
    value: string;
  };
  createdAt: number | null;
  displayName: string;
  entityName: string;
  firstName: string | null;
  kind: ClientKind;
  lastName: string | null;
  legalType: string | null;
  state: ClientState;
  tariff: {
    id: string;
    displayName: string;
    tariffGroup: {
      id: string;
      displayName: string;
    };
  };
  avgBalance?: string;
}

interface IExpense {
  expenseDay: number;
  expenseWeek: number;
  expenseYear: number;
  expenseMonth: number;
}

export interface IClientBaaSFull {
  bankEntityId: string;
  clientId: string;
  companyRegistrationNumber: string;
  countryId: string;
  createdAt: string;
  displayName: string;
  dob: string;
  entityName: string;
  entityType: string;
  firstName: string;
  id: string;
  kind: string;
  lastName: string;
  primaryCurrencyId: string;
  specialActivities: string;
  state: string;
  tariffId: string;
  updatedAt: string;
}

export interface IClientFull extends IClientShort {
  activatedAt: number | null;
  address: string | null;
  aidCase?: {
    lastSubmissionCompletedAt?: number;
    score?: string;
  };
  bankEntity: {
    id: string;
    displayName: string;
    businessCode?: string;
  };
  companyRegistrationNumber: string | null;
  dob: number | null;
  extendedData: IClientAidExtendedData | null;
  firstAccountCreatedAt: number | null;
  idNo: string | null;
  idType: unknown;
  limitTier: {
    displayName: string;
    id: string;
  };
  limits: {
    ach: IExpense;
    fast: IExpense;
    sepa: IExpense;
    wire: IExpense;
    swift: IExpense;
    fedWire: IExpense;
    internal: IExpense;
    cryptoAda: IExpense;
    cryptoBtc: IExpense;
    cryptoEth: IExpense;
    cryptoLtc: IExpense;
    cryptoDoge: IExpense;
  };
  may: {
    block: boolean;
    unblock: boolean;
    close: boolean;
    reactivate: boolean;
    updateOnHold: boolean;
    destroy: boolean;
    restore: boolean;
    addAidVerification: boolean;
  };
  tariffChangedAt: number | null;
  tariffCheckedAt: number | null;
  tariffState: string;
  features?: IClientFeature[];
}

export interface IClientsFilters extends IPaginated {
  search?: string;
  kind?: string | null;
  state?: string | null;
  countries: string[];
  pricingPlans: string[];
  groups: string[];
}
